import React from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <a 
        href="https://beian.miit.gov.cn/" 
        target="_blank" 
        rel="noreferrer">
          粤ICP备2024317790号-1
        </a>
        <p>@2024-{currentYear} zed168.com All rights reserved.</p>
      </footer>
    );
  };

export default Footer;